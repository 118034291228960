import { action, observable } from "mobx";

const moneyBagState = observable({
  feeTip: false,
  bagTip: false,
  isMoneyBagOpen: false,
});

const setFeeTip = action((state: boolean) => {
  moneyBagState.feeTip = state;
});

const setBagTip = action((state: boolean) => {
  moneyBagState.bagTip = state;
});

const setMoneyBag = action((state: boolean) => {
  moneyBagState.isMoneyBagOpen = state;
})

export const useMoneyBag = () => {
  const onBagTip = () => {
    setMoneyBag(false);
    setBagTip(true);
  };

  const onFeeTip = () => {
    setMoneyBag(false);
    setFeeTip(true);
  };

  const openMoneyBag = () => {
    setMoneyBag(true);
  };

  const closeMoneyBag = () => {
    setMoneyBag(false);
  };

  const onFeeClose = () => {
    setFeeTip(false);
    setMoneyBag(true);
  };

  const onBagTipClose = () => {
    setBagTip(false);
    setMoneyBag(true);
  };

  return {
    onBagTip,
    onFeeTip,
    openMoneyBag,
    closeMoneyBag,
    onFeeClose,
    onBagTipClose,
    moneyBagState,
  }
};
