import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";

import clsx from "clsx";

import { Icon } from "@/components/Icon";
import capyBag from "@/assets/capybara-images/capybag.png";
import capybaraNFT from "@/assets/capybara-images/capynft.png";
import sui from "@/assets/icons/sui.svg";
import Star1 from "@/assets/icons/star1.svg?react";
import Star2 from "@/assets/icons/star2.svg?react";

import { Badge } from "@/components/Badge";
import { Card, Count } from "@/components/Card/Card";
import { OpenMoneyBag, useMoneyBag } from "@/components/OpenMoneyBag";
import { ReceivedNftModal } from "@/components/ReceivedNft";

import { CollectTip, MintTip } from "./Modals";
import { Offers } from "./Offers";
import { Header } from "./Header/Header";
import { Collection } from "./Collection";
import {
  collectionsState,
  setCollectTip,
  setMintTip,
  setFeeTip,
  setReceivedModal,
} from "./state";

import styles from './styles.module.sass';

export type TFruit = {
  icon: string;
  collected: boolean;
  name: string;
  count?: number;
}

export type TMoneyBag = {
  name: string;
  bg: string,
  count: number,
  action: () => JSX.Element
};

export const Collections: FC = observer(() => {
  const hasSui = true;

  const fruits: TFruit[] = [
    {icon: `🍋`, collected: false, name: 'lemon', count: 1 },
    {icon: `🍓`, collected: false, name: 'strawberry', count: 2 },
    {icon: `🍌`, collected: true, name: 'banana', count: 1 },
    {icon: `🍉`, collected: true, name: 'watermelon' },
    {icon: `🍄`, collected: true, name: 'mushroom' },
  ];
  
  const bags = [
    {
      name: 'buy',
      bg: 'inherit',
      count: 0,
      action: () => (
        <Badge size="s" color="blue" className={clsx(styles.slide__badge, styles.badge_bottom)} width="85%">
          Buy for
          <i style={{ background: `url(${sui}) no-repeat center`, width: '15px', height: '15px', backgroundSize: 'contain' }} />
        </Badge>
      )
    },
    {
      name: 'open',
      bg: '#4DA2FF',
      count: 2,
      action: () => <></>
    },
  ];
  
  const allCollected = fruits.length && fruits.every(fruit => fruit.collected);

  const {
    onBagTip,
    onFeeTip,
    openMoneyBag,
    closeMoneyBag,
    onFeeClose,
    onBagTipClose,
    moneyBagState,
  } = useMoneyBag();

  // slider actions
  const openCollectTip = (state: boolean) => () => {
    setCollectTip(state)
  };

  const openMintTip = (state: boolean) => () => {
    setMintTip(state)
  };

  const openMintFeeTip = () => {
    setMintTip(false);
    setFeeTip(true);
  };

  const closeMintFeeTip = () => {
    setFeeTip(false);
    setMintTip(true);
  };

  const handleBuyForSUI = () => {
    //TODO: external marketplace
    console.log('buy: moved to external marketplace')
  };

  const handleSellForSUI = () => {
    //TODO: external marketplace
    console.log('sell: moved to external marketplace')
  };

  const handleMintNFT = () => {
    //TODO: mint NFT action
    console.log('mint NFT action');
  };

  const handleMintMoneyBag = () => {
    handleMintNFT();
  };

  const handleBuyForClick = () => {
    if (allCollected) {
      return handleMintNFT();
    }

    if (!allCollected) {
      return handleBuyForSUI();
    }
  }

  // collected food actions
  const handleFoodClick = ({ collected }: TFruit) => () => {
    if (collected) {
      return handleSellForSUI();
    }
    
    if (!collected) {
      return handleBuyForSUI();
    }
  };

  // money bag dialog actions
  const handleMoneyBagClick = ({ name }: TMoneyBag) => () => {
    if (name === 'open') {
      return openMoneyBag();
    }

    if (name === 'buy') {
      return handleBuyForSUI();
    }
  };

  const handleListForSale = () => {
    console.log('list for sale action');
  }

  // received modal actions
  const openReceivedModal = () => {
    closeMoneyBag();
    setReceivedModal(true);
  };

  const closeReceivedModal = () => {
    setReceivedModal(false);
  };

  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#1a64ce");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header />
      <div className="content">
        <Offers
          fruits={fruits}
          allCollected={!!allCollected}
          onMintForClick={handleMintMoneyBag}
          onMintNftClick={openMintTip(true)}
          onBuyForClick={handleBuyForClick}
          onTipClick={openCollectTip(true)}
        />
        <div className={styles.collections}>
          <Collection title="Money Bags">
            {
              bags.map(bag => (
                <Card
                  width="169px"
                  height="169px"
                  key={bag.name}
                  className={clsx(styles.card, styles.card__bag)}
                  shineBg
                  backgroundColor={bag.bg}
                  onClick={handleMoneyBagClick(bag)}
                >
                  <div style={{ background: `url(${capyBag}) no-repeat center`, width: '160px', height: '160px', backgroundSize: 'cover' }} />
                  <Star1 className={styles.star} />
                  <Star2 className={styles.star}/>
                  <Count count={bag.count || 0} width="36px" height="36px" position={{ top: '10px', right: '10px' }}/>
                  {bag.action()}
                </Card>
              ))
            }
          </Collection>
          <Collection title="Collected Food">
            {
                fruits.map(fruit => (
                  <Card
                    width="112px"
                    height="112px"
                    borderRadius="24px"
                    key={fruit.name + "collection"}
                    backgroundColor={fruit.collected ? '#4DA2FF' : '#A4A7F9'}
                    onClick={handleFoodClick(fruit)}
                  >
                    <Icon size="50px" content={<>{fruit.icon}</>} className={clsx({
                      [styles.icon_disabled]: !fruit.collected,
                    })}/>
                    <Count count={fruit.count || 0} width="24px" height="24px" position={{ top: '8px', right: '8px' }}/>
                    <Badge
                      size="m"
                      width="90%"
                      color={fruit.collected ? "transparent" : "blue"}
                      className={clsx(styles.badge_bottom)}
                    >
                      { fruit.collected ? 'SELL FOR' : 'BUY FOR' }
                      <i style={{ background: `url(${sui}) no-repeat center`, width: '20px', height: '15px', backgroundSize: 'contain'}} />
                    </Badge>
                  </Card>
                ))
              }
          </Collection>
          <Collection title="Capybara">
            {
              [capybaraNFT].map(img => (
                <Card width="169px" height="169px" background={`url(${img}) no-repeat center center / contain`} />
              ))
            }
          </Collection>
        </div>
      </div>
      <MintTip
        isOpen={collectionsState.mintTip}
        isFeeTip={collectionsState.feeTip}
        onClose={openMintTip(false)}
        onAction={handleMintNFT}
        onFeeTip={openMintFeeTip}
        closeFeeTip={closeMintFeeTip}
      />
      <CollectTip fruits={fruits} isOpen={collectionsState.collectTip} onClose={openCollectTip(false)}/>
      <OpenMoneyBag
        isOpen={moneyBagState.isMoneyBagOpen}
        isFeeOpen={moneyBagState.feeTip}
        isBagTipOpen={moneyBagState.bagTip}
        hasSui={hasSui}
        onClose={closeMoneyBag}
        onAction={openReceivedModal}
        onListForSale={handleListForSale}
        onFeeTip={onFeeTip}
        onBagTip={onBagTip}
        onFeeClose={onFeeClose}
        onBagTipClose={onBagTipClose}
      />
      <ReceivedNftModal
        isOpen={collectionsState.receivedModal}
        onClose={closeReceivedModal}
        onAction={closeReceivedModal}
        { ...collectionsState.receivedData }
      />
    </div>
  )
});
