const overflow = 100;

export const handleExpandPage = () => {
  document.body.style.overflowY = "hidden";
  document.body.style.marginTop = `${overflow}px`;
  document.body.style.height = `${window.innerHeight + overflow}px`;
  document.body.style.paddingBottom = `${overflow}px`;
  window.scrollTo(0, overflow);
};

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const openExternalUrl = (url: string) => {
  if (isIOS) {
    Telegram.WebApp.openLink(url);
  } else {
    window.open(url, "_blank");
  }
};

/**
 * Генерирует URL с параметрами на основе заданного пути и объекта параметров.
 * @param path - Путь на сайте, например "/StashedNftUpdate"
 * @param params - Объект с параметрами для добавления в URL
 * @returns Сгенерированный URL с закодированными параметрами
 */
export const externalLink = (
  path:
    | "/auth"
    | "/authWallets"
    | "/authStashed"
    | "/transferStashed"
    | "/StashedNftMint"
    | "/StashedNftUpdate"
    | "/nftMint",
  params: Record<string, string> = {}
) => {
  // Получаем текущий домен и протокол
  const baseUrl = `${window.location.protocol}//${window.location.host}${path}`;
  const url = new URL(baseUrl);

  // Добавляем параметры из объекта params
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, String(value));
    }
  });

  openExternalUrl(url.toString());
};
