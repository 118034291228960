import { FC } from "react";
import { observer } from "mobx-react-lite";

import capyBag from "@/assets/capybara-images/capybag.png";
import { Button } from "@/components/Button";

import styles from './styles.module.sass';
import { DialogComponents } from "@/components/Dialog";

type MoneyBagTipProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAction: VoidFunction;
};

export const MoneyBagTip: FC<MoneyBagTipProps> = observer(({
  isOpen,
  onClose,
  onAction,
}) => {
  return (
    <DialogComponents.Container
      overlayClassName={styles.modalOverlay}
      wrapperClassName={styles.modalContentWrapper}
      isOpen={Boolean(isOpen)}
      onAction={onAction}
      onClose={onClose}
    >
      {({ handleClose }) => (
        <DialogComponents.Content className={styles.modalContent}>
          <DialogComponents.Closer
            className={styles.modalCloser}
            onClick={handleClose}
          ></DialogComponents.Closer>
          <div className={styles.capybag}>
            <img alt="capybag" src={capyBag} style={{maxWidth: "183px"}}/>
          </div>
          <DialogComponents.TextContainer className={styles.textContainer}>
            <DialogComponents.Title className={styles.modalTitle}>
              {`Unlock the mystery!`}
            </DialogComponents.Title>
            <DialogComponents.Text className={styles.modalText}>
              {
                `This Money Bag NFT contains one random Capybara Food NFT. Collect all 5 different Food NFTs to mint an exclusive Capybara NFT and receive a special bonus!\n 
                You can get your Food NFTs by opening Money Bags or purchasing them on the marketplace. You can also list your NFTs for sale! Start your collection now and enjoy the rewards!`
              }
            </DialogComponents.Text>
          </DialogComponents.TextContainer>
          <div style={{ width: '100%' }}>
            <Button
              onClick={handleClose}
              label="Got it!"
              styleType="primary"
              block={true}
            ></Button>
          </div>
        </DialogComponents.Content>
      )}
    </DialogComponents.Container>
  )
});