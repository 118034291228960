import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

import capyBag from "@/assets/capybara-images/capybag.png";
import Star1 from "@/assets/icons/star1.svg?react";
import Coin from "@/assets/capybara-images/coin.svg?react";
import Info from "@/assets/icons/info.svg?react";

import { CoinsDisplay } from "@/components/CoinsDisplay";

import styles from "./styles.module.sass";
import { Button } from "@/components/Button";
import { Card } from "@/components/Card";
import { OpenMoneyBag, useMoneyBag } from "@/components/OpenMoneyBag";
import { Badge } from "@/components/Badge";
import { MoneyBagTip } from "@/components/MoneyBagTip";
import { ReceivedNftModal } from "@/components/ReceivedNft";

import {
  buyMoneyBagState,
  setBagTip,
  setAmount,
} from "./state";
import { lootboxesStore } from "@/store";

export const BuyMoneyBag: FC = observer(() => {
  const { openReceiveModal, buy, receiveModal, receivedData } = lootboxesStore;
  const hasCoins = true;
  const hasSui = true;

  const {
    onBagTip,
    onFeeTip,
    openMoneyBag,
    closeMoneyBag,
    onFeeClose,
    onBagTipClose,
    moneyBagState,
  } = useMoneyBag();

  const openBagTip = (state: boolean) => () => {
    setBagTip(state);
  };

  const handleConfirm = () => {
    closeMoneyBag();
    buy([1]);
    openReceiveModal(true);
  };

  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#1a64ce");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <span style={{ padding: '5px'}} className={clsx(styles.text, styles.text_poppins, styles.text_16)}>Your Balance</span>
          <CoinsDisplay />
        </header>
        <div className={styles.content}>
          <div className={styles.capybag}>
            <Star1 className={styles.star}/>
            <img alt="capybag" src={capyBag} style={{maxWidth: "323px"}}/>
            <Badge className={styles.tip} color="transparent" onClick={openBagTip(true)}>
              <span>What's inside?</span>
              <Info width={12} height={12}/>
            </Badge>
          </div>
        </div>
        <div className={styles.buy}>
          <div className={styles.buy__info}>
            <Card width="100%" className={styles.buy__card} borderRadius="24px">
              <div className={styles.buy__description}>
                <span className={clsx(styles.text, styles.text_poppins, styles.text_12)}>Amount of</span>
                <span className={clsx(styles.text, styles.text_poppins, styles.text_20)}>Money Bags</span>
              </div>
              <div className={styles.buy__input}>
                <button className={styles.buy__control} onClick={() => setAmount(-1)}>-</button>
                <span className={clsx(styles.text, styles.text_gluten, styles.text_20)}>{ buyMoneyBagState.amount }</span>
                <button className={styles.buy__control} onClick={() => setAmount(1)}>+</button>
              </div>
            </Card>
            <Card width="49%" className={styles.buy__card} borderRadius="24px">
              <span className={clsx(styles.text, styles.text_poppins, styles.text_12)}>Money Bag Price</span>
              <span style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
                <Coin width={24} height={20}/>
                <span className={clsx(styles.text, styles.text_gluten, styles.text_20)}>1,000,000</span>
              </span>
            </Card>
            <Card width="49%" className={styles.buy__card} borderRadius="24px">
              <span className={clsx(styles.text, styles.text_poppins, styles.text_12)}>Mint Fee</span>
              <span className={clsx(styles.text, styles.text_gluten, styles.text_20)}>0.001 SUI</span>
            </Card>
          </div>
          {hasCoins && (
            <Button
              onClick={openMoneyBag}
              label="Confirm"
              styleType="primary"
              block={true}
            />
          )}
          {!hasCoins && (
            <Button
              label="Not enough coins to mint"
              styleType="info"
              block={true}
            />
          )}
        </div>
      </div>
      <MoneyBagTip
        isOpen={buyMoneyBagState.bagTip}
        onAction={openBagTip(true)}
        onClose={openBagTip(false)}
      />
      <ReceivedNftModal
        isOpen={receiveModal}
        onAction={() => openReceiveModal(false)}
        onClose={() => openReceiveModal(false)}
        {...receivedData}
      />
      <OpenMoneyBag
        isOpen={moneyBagState.isMoneyBagOpen}
        isFeeOpen={moneyBagState.feeTip}
        isBagTipOpen={moneyBagState.bagTip}
        hasSui={hasSui}
        onClose={closeMoneyBag}
        onAction={handleConfirm}
        onListForSale={() => null}
        onFeeTip={onFeeTip}
        onBagTip={onBagTip}
        onFeeClose={onFeeClose}
        onBagTipClose={onBagTipClose}
      />
    </>
  )
});
