import { observer } from "mobx-react-lite";
import { walletStore } from "@/store";
import { balance } from "@/utils";
import { useCurrentAccount } from "@mysten/dapp-kit";
import styles from "./styles.module.sass";

interface TokenComponentProps {
  imgLink: string;
  currency: string;
}

const TokenComponent = observer(
  ({ imgLink, currency }: TokenComponentProps) => {
    const currentAccount = useCurrentAccount();
    return (
      currentAccount?.address && (
        <div className={styles.tokenContent}>
          <img src={imgLink} />
          <div className={styles.currencyContent}>
            <div className={styles.currencyWrapper}>
              <p className={styles.currency}>{currency}</p>
              <p className={styles.currencyBalance}>
                {balance(walletStore.balance) + " SUI"}
              </p>
            </div>
            <div className={styles.currency}>
              ${balance(walletStore.usdBalance, 4)}
            </div>
          </div>
        </div>
      )
    );
  }
);

export default TokenComponent;
