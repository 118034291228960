import { useEffect, useState } from "react";
import { Transaction } from "@mysten/sui/transactions";
import { MIST_PER_SUI } from "@mysten/sui/utils";
import {
  useCurrentAccount,
  useSignAndExecuteTransaction,
} from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { isIOS } from "@/utils/handleExpandPage";
import { QueryWrapper } from "./QueryWrapper";
import { handleClose } from "../walletUtils";

export const StashedTransfer = () => {
  const query = new URLSearchParams(window.location.search);
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(true);
  const currentAccount = useCurrentAccount();

  const transfer = (amount: string, sender: string, reciever: string) => {
    setIsLoader(true);

    if (query.has("sender") && !transacrionInWork) {
      setTransacrionInWork(true);
      const txb = new Transaction();
      const handledAmount = Number(amount) * Number(MIST_PER_SUI);
      const [coin] = txb.splitCoins(txb.gas, [handledAmount]);
      txb.setSender(sender);
      txb.transferObjects([coin], reciever);
      signAndExecuteTransaction(
        {
          transaction: txb,
          chain: "sui:mainnet",
        },
        {
          onSuccess: () => {
            if (isIOS) {
              const url = new URL(window.location.href);
              url.search = "";
              window.history.replaceState(null, "", url.toString());
              handleClose()
            } else {
              window.close();
            }
            setIsLoader(false);
          },
        }
      );
    }
  };

  useEffect(() => {
    localStorage.removeItem("stashed:recentAddress");
    localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
  }, []);

  useEffect(() => {
    if (currentAccount?.address) {
      if (isIOS) {
        document.getElementById("transferButton")?.click();
      }
      setStashedWalletIsOpen(false);
    }
  }, [currentAccount]);

  return (
    <QueryWrapper requiredParams={["reciever", "amount", "sender"]}>
      {({ queryParams, handleClose }) => (
        <>
          {isLoader && <Loader />}
          {!isIOS && (
            <Dialog
              modalWallpaper={walletBg}
              isOpen={!stashedWalletIsOpen}
              onClose={handleClose}
              onAction={() =>
                transfer(
                  queryParams.amount,
                  queryParams.sender,
                  queryParams.reciever
                )
              }
              title={"Connecting is finished"}
              text={"Click the button below to approve the transaction"}
              modalTitleColor={"#C0C3FF"}
              modalIcon={iosWalletImg}
              buttonText={"Approve transaction"}
            />
          )}
          <button
            style={{
              opacity: 0,
            }}
            onClick={() =>
              transfer(
                queryParams.amount,
                queryParams.sender,
                queryParams.reciever
              )
            }
            id="transferButton"
          >
            Transfer
          </button>
        </>
      )}
    </QueryWrapper>
  );
};
