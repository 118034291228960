import { useEffect, useState } from "react";
import {
  useCurrentAccount,
  useSignAndExecuteTransaction,
} from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { isIOS } from "@/utils/handleExpandPage";
import { buildCheckinTx } from "@/utils/mintNft";

import {
  apiProduction,
  apiStage,
  isProdUrl,
  PACKAGE_ADDRESS,
  REGISTRY_ADDRESS,
  TREASURY_ADDRESS,
} from "@/constants";
import ky from "ky";
import { QueryWrapper } from "./QueryWrapper";
import { handleClose } from "../walletUtils";

const handleSuccess = (
  result: {
    digest: string;
  },
  setIsLoader: (value: boolean) => void,
  setIsError: (value: boolean) => void,
  base: string,
  userId: string,
  token: string,
  isUpdate?: boolean
) => {
  if (result.digest) {
    ky.post(base + `/api/nft/${isUpdate ? "update_tx" : "mint_tx"}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      json: {
        tx_digest: result.digest,
        user_id: userId,
      },
    })
      .then((result) => result.json() as Promise<{ ok: boolean; err: string }>)
      .then((data) => {
        if (data.ok) {
          if (isIOS) {
            const url = new URL(window.location.href);
            url.search = "";
            window.history.replaceState(null, "", url.toString());
            handleClose();
            setIsLoader(false);
          } else {
            window.close();
          }
        } else {
          console.log(
            `Error ${isUpdate ? "updating" : "minting"} nft`,
            data.err
          );
          setIsError(true);
        }
      });
  }
};

export const StashedNftUpdate = () => {
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const base = isProdUrl ? apiProduction : apiStage;
  const currentAccount = useCurrentAccount();

  const transfer = (
    reciever: string,
    nftId: string,
    token: string,
    coin: string,
    league: string,
    isUpdate?: boolean
  ) => {
    setIsLoader(true);
    isError && setIsError(false);

    if (!transacrionInWork) {
      setTransacrionInWork(true);

      const checkinTx = buildCheckinTx({
        functionParams: {
          nftAddress: nftId,
          treasury: TREASURY_ADDRESS,
          registry: REGISTRY_ADDRESS,
          fee: 0,
          validUntil: 1,
          points: coin ? Number(coin) : undefined,
          league: league ? Number(league) : undefined,
          signature: new Uint8Array(0),
        },
        packageAddress: PACKAGE_ADDRESS,
        userAddress: reciever,
      });

      signAndExecuteTransaction(
        {
          transaction: checkinTx,
          chain: "sui:mainnet",
        },
        {
          onSuccess: (result) =>
            handleSuccess(
              result,
              setIsLoader,
              setIsError,
              base,
              reciever,
              token,
              isUpdate
            ),
          onError: () => {
            setIsLoader(false);
            setIsError(true);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (currentAccount?.address) {
      setIsOpen(true);
    }
  }, [currentAccount]);

  return (
    <QueryWrapper
      requiredParams={[
        "reciever",
        "nftId",
        "userId",
        "token",
        "coin",
        "league",
      ]}
    >
      {({ queryParams, handleClose }) => (
        <>
          {isLoader && <Loader />}

          <Dialog
            modalWallpaper={walletBg}
            isOpen={isOpen}
            onClose={handleClose}
            onAction={() =>
              transfer(
                queryParams.reciever,
                queryParams.nftId,
                queryParams.token,
                queryParams.coin,
                queryParams.league,
                true
              )
            }
            title={"Connecting is finished"}
            text={"Click the button below to update attributes"}
            modalTitleColor={"#C0C3FF"}
            modalIcon={iosWalletImg}
            buttonText={"Update Attributes"}
          />

          <Dialog
            modalWallpaper={walletBg}
            isOpen={isError}
            onClose={handleClose}
            onAction={() =>
              transfer(
                queryParams.reciever,
                queryParams.nftId,
                queryParams.token,
                queryParams.points,
                queryParams.league
              )
            }
            title={"Something went wrong"}
            text={"Click the button below to try again"}
            modalTitleColor={"#C0C3FF"}
            modalIcon={iosWalletImg}
            buttonText={"Mint NFT again"}
          />
          <button
            style={{
              opacity: 0,
            }}
            onClick={() =>
              transfer(
                queryParams.reciever,
                queryParams.nftId,
                queryParams.token,
                queryParams.points,
                queryParams.league
              )
            }
            id="transferButton"
          >
            mint
          </button>
        </>
      )}
    </QueryWrapper>
  );
};
