// QueryWrapper.tsx
import { useEffect, useState, ReactNode } from "react";
import { isIOS } from "@/utils/handleExpandPage";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import { handleClose } from "../walletUtils";

interface QueryWrapperProps<T> {
  children: (props: { queryParams: T; handleClose: () => void }) => ReactNode;
  requiredParams?: (keyof T)[];
  nonRequiredParams?: (keyof T)[];
}

export const QueryWrapper = <T extends Record<string, string>>({
  children,
  requiredParams = [],
  nonRequiredParams = [],
}: QueryWrapperProps<T>) => {
  const currentAccount = useCurrentAccount();
  const [queryParams, setQueryParams] = useState<Partial<T>>({});
  const [isValid, setIsValid] = useState(true);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(true);

  useEffect(() => {
    localStorage.removeItem("stashed:recentAddress");
    localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
  }, []);

  useEffect(() => {
    if (currentAccount?.address) {
      if (isIOS) {
        document.getElementById("transferButton")?.click();
      }
      setStashedWalletIsOpen(false);
    }
  }, [currentAccount]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params: Partial<T> = {};

    requiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      } else {
        setIsValid(false);
      }
    });

    nonRequiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      }
    });

    setQueryParams(params);
  }, [requiredParams, nonRequiredParams]);


  return isValid ? (
    <>
      <ConnectModal
        open={stashedWalletIsOpen}
        trigger={<></>}
        onOpenChange={(result) => {
          console.log(result);
        }}
      />
      {!stashedWalletIsOpen &&
        children({ queryParams: queryParams as T, handleClose })}
    </>
  ) : (
    "some params are missing"
  );
};
