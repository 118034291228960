import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";
import Info from "@/assets/layout/rating/info.svg?react";
import { observer } from "mobx-react-lite";

import NftBorder from "@/assets/capybara-images/nftBorder.png";
import NftImg from "@/assets/capybara-images/nftImg.png";
import MintinImg from "@/assets/capybara-images/MintingBackground.png";
import clsx from "clsx";
import { Button } from "../Button";
import SuiImg from "@/assets/capybara-images/sui-80.png";
import { useNavigate } from "react-router-dom";
import { isProduction } from "@/utils";
import { QueryWrapper } from "../CreateWallet/components/QueryWrapper";
import { useEffect, useState } from "react";
import { useSignAndExecuteTransaction } from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import { isIOS } from "@/utils/handleExpandPage";
import { buildCheckinTx } from "@/utils/mintNft";

import {
  apiProduction,
  apiStage,
  isProdUrl,
  PACKAGE_ADDRESS,
  REGISTRY_ADDRESS,
  TREASURY_ADDRESS,
} from "@/constants";
import ky from "ky";
import { handleClose } from "../CreateWallet/walletUtils";

const getNftImg = (
  capybaraNftStatus: "MINTED" | "MINTING" | "MINTABLE" | "UPDATABLE"
) => {
  switch (capybaraNftStatus) {
    case "MINTED":
    case "MINTABLE":
      return NftImg;
    case "MINTING":
      return MintinImg;
    case "UPDATABLE":
      return MintinImg;
  }
};

const handleSuccess = (
  result: {
    digest: string;
  },
  setIsLoader: (value: boolean) => void,
  setIsError: (value: boolean) => void,
  base: string,
  userId: string,
  token: string,
  isUpdate?: boolean
) => {
  if (result.digest) {
    ky.post(base + `/api/nft/${isUpdate ? "update_tx" : "mint_tx"}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      json: {
        tx_digest: result.digest,
        user_id: userId,
      },
    })
      .then((result) => result.json() as Promise<{ ok: boolean; err: string }>)
      .then((data) => {
        if (data.ok) {
          if (isIOS) {
            const url = new URL(window.location.href);
            url.search = "";
            window.history.replaceState(null, "", url.toString());
            handleClose();
            setIsLoader(false);
          } else {
            window.close();
          }
        } else {
          console.log(
            `Error ${isUpdate ? "updating" : "minting"} nft`,
            data.err
          );
          setIsError(true);
        }
      });
  }
};

export const MintingNft = observer(() => {
  const [updatedAt, setUpdatedAt] = useState<number | null>(null);
  const [capybaraNftStatus, setCapybaraNftStatus] = useState<
    "MINTING" | "UPDATABLE"
  >("MINTING");
  const navigate = useNavigate();
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const base = isProdUrl ? apiProduction : apiStage;

  const transfer = (
    reciever: string,
    nftId: string,
    token: string,
    coin: string,
    league: string,
    userId: string,
    isUpdate?: boolean
  ) => {
    setIsLoader(true);
    isError && setIsError(false);

    if (!transacrionInWork) {
      setTransacrionInWork(true);

      const checkinTx = buildCheckinTx({
        functionParams: {
          nftAddress: nftId,
          treasury: TREASURY_ADDRESS,
          registry: REGISTRY_ADDRESS,
          fee: 0,
          validUntil: 1,
          points: coin ? Number(coin) : undefined,
          league: league ? Number(league) : undefined,
          signature: new Uint8Array(0),
        },
        packageAddress: PACKAGE_ADDRESS,
        userAddress: reciever,
      });

      signAndExecuteTransaction(
        {
          transaction: checkinTx,
          chain: "sui:mainnet",
        },
        {
          onSuccess: (result) =>
            handleSuccess(
              result,
              setIsLoader,
              setIsError,
              base,
              userId,
              token,
              isUpdate
            ),
          onError: () => {
            setIsLoader(false);
            setIsError(true);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (
      updatedAt &&
      (isProduction
        ? updatedAt + 24 * 3600 < Date.now() / 1000
        : updatedAt + 60 < Date.now() / 1000)
    ) {
      setCapybaraNftStatus("UPDATABLE");
    }
    Telegram.WebApp.setHeaderColor("#fff");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, [updatedAt]);

  return (
    <QueryWrapper
      requiredParams={[
        "earn",
        "leagueName",
        "reciever",
        "token",
        "userId",
        "nftId",
        "coin",
        "league",
      ]}
      nonRequiredParams={["updatedAt"]}
    >
      {({ queryParams }) => {
        if (queryParams.updatedAt) {
          setUpdatedAt(+queryParams.updatedAt);
        }

        return (
          <>
            {isLoader && <Loader />}

            <div className={styles.capybaraContainer}>
              <div
                className={clsx(styles.capybaraCard, {
                  [styles.minted]: capybaraNftStatus === "UPDATABLE",
                  [styles.minting]: capybaraNftStatus === "MINTING",
                })}
              >
                <div className={styles.additionalDataText}>
                  {capybaraNftStatus === "MINTING" && (
                    <p>
                      Player Card
                      <br /> NFT
                    </p>
                  )}
                </div>
                <img className={styles.nftBackground} src={NftBorder} />
                <img
                  draggable="false"
                  className={styles.capybaraImg}
                  src={getNftImg(capybaraNftStatus)}
                  alt="state"
                />

                <div
                  className={clsx(styles.capybaraAttributesWrapper, {
                    [styles.capybaraAttributesMinted]:
                      capybaraNftStatus === "UPDATABLE",
                  })}
                >
                  <div className={styles.capybaraAttributesContainer}>
                    <p className={clsx(styles.capybaraAttributesTitle)}>
                      Attributes:
                    </p>
                    <div className={styles.capybaraAttributesBlock}>
                      <p>
                        Coin Earned:
                        <Coin width={12} height={12} /> {queryParams.coin}
                      </p>
                      <p> League: {queryParams.leagueName} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.feeContainer}>
                <div className={styles.feeInfo}>
                  <p>Transaction Fee</p>
                  <p className={styles.amount}>0.001 SUI</p>
                </div>
                <Info />
              </div>
              {capybaraNftStatus === "MINTING" && (
                <Button
                  onClick={() => {
                    transfer(
                      queryParams.reciever,
                      queryParams.nftId,
                      queryParams.token,
                      queryParams.coin,
                      queryParams.league,
                      queryParams.userId
                    );
                  }}
                  block={true}
                  label={"Mint Player Card NFT"}
                />
              )}

              {capybaraNftStatus === "UPDATABLE" && (
                <Button
                  onClick={() => {
                    transfer(
                      queryParams.reciever,
                      queryParams.nftId,
                      queryParams.token,
                      queryParams.coin,
                      queryParams.league,
                      queryParams.userId,
                      true
                    );
                  }}
                  block={true}
                  label={"Update Attributes"}
                />
              )}

              <Dialog
                modalWallpaper={""}
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(false);
                }}
                onAction={() => navigate("/wallet")}
                title={"Not enough SUI"}
                text={`You don’t have enough SUI in your wallet to cover the Transaction Fee.
           Please Buy SUI or deposit from external wallet.`}
                modalTitleColor={"#C0E6FF;"}
                modalIcon={<img width={80} height={80} src={SuiImg} />}
                buttonText={"Buy"}
                additionalbuttonText="Deposit"
                handleAdditionalAction={() => navigate("/wallet?receive=true")}
              />
            </div>
          </>
        );
      }}
    </QueryWrapper>
  );
});
