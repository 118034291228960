import rootStore from "@/store";
import { handleExpandPage } from "@/utils/handleExpandPage";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const backButtonHandleClick = () => window.history.back();

const handleViewHeight = () => {
  if (!Telegram.WebApp.isExpanded) {
    Telegram.WebApp.expand();
    setTimeout(() => handleExpandPage(), 0);
  }
};

const TelegramHeaderManager = observer(() => {
  const { gameReady } = rootStore;
  const location = useLocation();

  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#000000");

    Telegram.WebApp.onEvent("viewportChanged", handleViewHeight);
    window.addEventListener("resize", handleExpandPage);

    return () => {
      Telegram.WebApp.offEvent("viewportChanged", handleViewHeight);
      window.removeEventListener("resize", handleExpandPage);
    };
  }, []);

  useEffect(() => {
    handleExpandPage();
  }, [gameReady, location.pathname]);

  useEffect(() => {
    Telegram.WebApp.BackButton.onClick(backButtonHandleClick);
  }, [location.pathname]);

  return null;
});

export default TelegramHeaderManager;
