import { useEffect, useState } from "react";
import {
  ConnectModal,
  useCurrentAccount,
  useSignAndExecuteTransaction,
} from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { isIOS } from "@/utils/handleExpandPage";
import { buildMintNftTx } from "@/utils/mintNft";
import {
  apiProduction,
  apiStage,
  isProdUrl,
  PACKAGE_ADDRESS,
  REGISTRY_ADDRESS,
} from "@/constants";
import ky from "ky";
import { handleClose } from "../walletUtils";

export const StashedNftMint = () => {
  const query = new URLSearchParams(window.location.search);
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const base = isProdUrl ? apiProduction : apiStage;

  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(true);
  const currentAccount = useCurrentAccount();

  const transfer = () => {
    setIsLoader(true);
    isError && setIsError(false);

    if (query.has("reciever") && !transacrionInWork) {
      setTransacrionInWork(true);

      const mintNftTx = buildMintNftTx({
        packageAddress: PACKAGE_ADDRESS,
        league: 0,
        points: 100,
        registryAddress: REGISTRY_ADDRESS,
        signature: new Uint8Array(0),
        sigValidUntil: 1,
      });

      signAndExecuteTransaction(
        {
          transaction: mintNftTx,
          chain: "sui:mainnet",
        },
        {
          onSuccess: (result) => {
            if (result.digest) {
              ky.post(base + "/api/nft/mint_tx", {
                headers: {
                  Authorization: `Bearer ${query.get("token") ?? ""}`,
                },
                json: {
                  tx_digest: result.digest,
                  user_id: query.get("user_id"),
                },
              })
                .then(
                  (result) =>
                    result.json() as Promise<{ ok: boolean; err: string }>
                )
                .then((data) => {
                  if (data.ok) {
                    if (isIOS) {
                      const url = new URL(window.location.href);
                      url.search = "";
                      window.history.replaceState(null, "", url.toString());
                      handleClose();
                      setIsLoader(false);
                    } else {
                      window.close();
                    }
                  } else {
                    console.log("Error minting NFT", data.err);
                    setIsError(true);
                  }
                });
            }
          },
          onError: () => {
            setIsLoader(false);
            setIsError(true);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (currentAccount?.address) {
      if (isIOS) {
        document.getElementById("transferButton")?.click();
      }
      setStashedWalletIsOpen(false);
    }
  }, [currentAccount]);

  return (
    <>
      {isLoader && <Loader />}
      {query.has("reciever") && (
        <ConnectModal
          open={stashedWalletIsOpen}
          trigger={<></>}
          onOpenChange={() => {
            console.log();
          }}
        />
      )}
      {query.has("reciever") && !isIOS && (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={!stashedWalletIsOpen}
          onClose={handleClose}
          onAction={() => transfer()}
          title={"Connecting is finished"}
          text={"Click the button below to mint NFT"}
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Approve mint"}
        />
      )}

      <Dialog
        modalWallpaper={walletBg}
        isOpen={isError}
        onClose={handleClose}
        onAction={() => transfer()}
        title={"Something went wrong"}
        text={"Click the button below to try again"}
        modalTitleColor={"#C0C3FF"}
        modalIcon={iosWalletImg}
        buttonText={"Mint NFT again"}
      />
      <button
        style={{
          opacity: 0,
        }}
        onClick={transfer}
        id="transferButton"
      >
        mint
      </button>
    </>
  );
};
