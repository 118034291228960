import { apiProduction, apiStage, isProdUrl } from "@/constants";
import ky from "ky";
import { useEffect, useState } from "react";
import walletSuccess from "@/assets/capybara-images/walletSuccess.png";
import walletBg from "@/assets/capybara-images/walletBg.png";
import { Dialog } from "@/components/Dialog";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import { handleClose } from "../walletUtils";
import Loader from "@/layout/Loader";

const base = isProdUrl ? apiProduction : apiStage;

export const StashedAuth = () => {
  const [isTransferComplete, setIsTransferComplete] = useState(false);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(true);
  const currentAccount = useCurrentAccount();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const dialog = document.querySelector('[role="dialog"]');
      const button = dialog?.querySelector('button[type="button"]');
      if (!currentAccount?.address && button) {
        (button as HTMLButtonElement)?.click();
      }
    }, 0);

    const query = new URLSearchParams(window.location.search);

    if (currentAccount?.address) {
      try {
        ky.post(base + "/api/context?provider=trust_wallet", {
          headers: {
            Authorization: `Bearer ${query.get("token") ?? ""}`,
          },
          json: {
            hash: currentAccount?.address,
          },
        });
      } catch (e) {
        console.log(e);
      }

      setStashedWalletIsOpen(false);
      setIsTransferComplete(true);
    }
  }, [currentAccount, stashedWalletIsOpen]);

  return (
    <>
      {isLoader && <Loader />}
      <ConnectModal
        open={stashedWalletIsOpen}
        trigger={<></>}
        onOpenChange={() => {
          console.log();
        }}
      />

      <Dialog
        modalWallpaper={walletBg}
        isOpen={isTransferComplete}
        onClose={() => {
          setIsLoader(true);
          handleClose();
        }}
        onAction={() => {
          setIsLoader(true);
          handleClose();
        }}
        title={"Connecting is finished"}
        text={"New wallet connected you can return to telegram"}
        modalTitleColor={"#C0C3FF"}
        modalIcon={walletSuccess}
        buttonText={"Return to Telegram"}
      />
    </>
  );
};
