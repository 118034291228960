import { observer } from "mobx-react-lite";
import { rootStore, gameStore } from "@/store";
import { Routes, Route } from "react-router-dom";
import { isDevelopment, isProduction, isTelegram } from "@/utils";
import clsx from "clsx";
import "@mysten/dapp-kit/dist/index.css";
import 'swiper/css/pagination';
import "swiper/css";
import Loader from "@/layout/Loader";
import LockScreen from "./layout/LockScreen";
import ErrorHint from "@/components/ErrorHint";
import DevOverlay from "./layout/DevOverlay";
import TelegramHeaderManager from "./components/TelegramHeaderManager";
import ToastContainer from "./components/ToastContainer/ToastContainer";
import CapybaraLayout from "./layout/CapybaraLayout";
import { menuItems } from "./constants";
import { LeagueLevelUpModal } from "./layout/LevelUpModal";
import Story from "./layout/Story";
import WalletLayout from "./layout/WalletLayout";
import WalletAuthLayout from "./layout/WalletAuthLayout";
import { AuthWalletsFlow } from "./components/AuthWallets";
import { StashedAuth } from "./components/CreateWallet/components/StashedAuth";
import { StashedAuthAndTransfer } from "./components/CreateWallet/components/StashedAuthAndTransfer";
import WalletTransfer from "./views/WalletTransfer";
import { StashedTransfer } from "./components/CreateWallet/components/StashedTransfer";
import { AutoFeedBotEarnedModal } from "./views/Boost/Modals/AutoFeedBotEarned";
import WalletBuy from "./views/WalletBuy";
import WalletPage from "./views/WalletPage";
import { Collections } from "./views/Collections";
import { BuyMoneyBag } from "./views/BuyMoneyBag";
import { EnokiProvider } from "./components/EnokiProvider";
import { StashedProvider } from "./components/StashedProvider";
import { Explore } from "./views/Earn/Explore";
import { StashedNftMint } from "./components/CreateWallet/components/StashedNftMint";
import { StashedNftUpdate } from "./components/CreateWallet/components/StashedNftUpdate";
import { MintingNft } from "./components/MintingNft";

const App = observer(() => {
  const className = clsx("appLayout", { ["isDevelopment"]: isDevelopment });
  
  if (window.location.pathname === "/auth") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <EnokiProvider>
            <StashedAuthAndTransfer />
          </EnokiProvider>
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/authWallets") {
    return (
      <WalletAuthLayout>
        <EnokiProvider>
          <AuthWalletsFlow />
        </EnokiProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/authStashed") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedAuth />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/transferStashed") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedTransfer />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/StashedNftMint") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedNftMint />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/StashedNftUpdate") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedNftUpdate />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/nftMint") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <MintingNft />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  return (
    <div className={className}>
      {isTelegram && <TelegramHeaderManager />}
      {rootStore.error && <ErrorHint />}
      {rootStore.qr && <LockScreen />}
      {rootStore.loading && !rootStore.qr && <Loader />}
      {/* <ImproveScreen /> */}
      {rootStore.gameReady && (
        <>
          {!isProduction && gameStore.devOverlay && <DevOverlay />}
          <ToastContainer />
          <Story />
          <LeagueLevelUpModal />
          <AutoFeedBotEarnedModal />
          <Routes>
            <Route
              key={"wallet"}
              path="/wallet"
              element={
                <StashedProvider>
                  <WalletLayout>
                    <WalletPage />
                  </WalletLayout>
                </StashedProvider>
              }
            />
            <Route
              key={"WalletTransfer"}
              path="/WalletTransfer"
              element={
                <StashedProvider>
                  <WalletLayout>
                    <WalletTransfer />
                  </WalletLayout>
                </StashedProvider>
              }
            />
            <Route
              key={"WalletBuy"}
              path="/WalletBuy"
              element={
                <StashedProvider>
                  <WalletLayout>
                    <WalletBuy />
                  </WalletLayout>
                </StashedProvider>
              }
            />

            <Route
              key={"Explore"}
              path="/explore/:id"
              element={
                <CapybaraLayout isHideMenu={false}>
                  <Explore />
                </CapybaraLayout>
              }
            />

            <Route
              key={"Collections"}
              path="/collections"
              element={
                <CapybaraLayout>
                  <Collections />
                </CapybaraLayout>
              }
            />

            <Route
              key={"BuyMoneyBag"}
              path="/buyMoneyBag"
              element={
                <BuyMoneyBag />
              }
            />

            {menuItems.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={
                  <CapybaraLayout isHideMenu={item.isHideMenu}>
                    <item.component />
                  </CapybaraLayout>
                }
              />
            ))}
          </Routes>
        </>
      )}
    </div>
  );
});

export default App;
