import { apiProduction, apiStage, isProdUrl } from "@/constants";
import ky from "ky";
import { useEffect, useState } from "react";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import walletSuccess from "@/assets/capybara-images/walletSuccess.png";
import walletBg from "@/assets/capybara-images/walletBg.png";
import styles from "../styles.module.sass";
import { useAuthCallback, useEnokiFlow, useZkLogin } from "@mysten/enoki/react";
import { toastManagerStore } from "@/store";
import { Dialog } from "@/components/Dialog";
import {
  ConnectModal,
  useCurrentAccount,
  useSuiClient,
  useSuiClientQuery,
} from "@mysten/dapp-kit";
import WalletCopy from "@/assets/capybara-images/walletCopy.svg?react";
import { balance, shortenText } from "@/utils";
import { Transaction } from "@mysten/sui/transactions";
import Loader from "@/layout/Loader";
import { handleClose } from "../walletUtils";

const base = isProdUrl ? apiProduction : apiStage;

export const StashedAuthAndTransfer = () => {
  const { handled } = useAuthCallback();
  const { address } = useZkLogin();
  const [isOpenTransfer, setIsOpenTransfer] = useState(false);
  const [isTransferComplete, setIsTransferComplete] = useState(false);
  const [stashedBalance, setStashedBalance] = useState<string>("");

  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const currentAccount = useCurrentAccount();
  const enokiFlow = useEnokiFlow();
  const client = useSuiClient();

  const { data, isFetched } = useSuiClientQuery("getBalance", {
    owner: address as string,
  });

  const getBalance = (owner: string) => {
    return client
      .getBalance({
        owner,
      })
      .then((balance) => {
        setStashedBalance(balance.totalBalance);
      });
  };

  async function handleTransferClick() {
    if (Number(data?.totalBalance) > 4_000_000) {
      try {
        const keypair = await enokiFlow.getKeypair({
          network: "mainnet",
        });
        const txb = new Transaction();
        const [coin] = txb.splitCoins(txb.gas, [
          Number(data?.totalBalance) - 4_000_000,
        ]);

        txb.setSender(keypair.getPublicKey().toSuiAddress());
        txb.transferObjects([coin], currentAccount?.address as string);

        await client.signAndExecuteTransaction({
          signer: keypair,
          transaction: txb,
        });
        setIsLoader(false);
        setIsTransferComplete(true);
      } catch (e) {
        console.error(e);
      }
    } else {
      setIsLoader(false);
      setIsTransferComplete(true);
    }
  }

  useEffect(() => {
    if (handled) {
      setIsOpenTransfer(true);
    }
  }, [handled, address]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    localStorage.setItem(
      "jwtToken",
      JSON.parse(params.get("state") ?? "")?.token ?? ""
    );

    ky.post(base + "/api/context?provider=google", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken") ?? ""}`,
      },
      json: {
        hash: params.get("id_token"),
      },
    });
  }, []);

  useEffect(() => {
    if (currentAccount?.address) {
      setIsLoader(true);

      try {
        ky.post(base + "/api/context?provider=trust_wallet", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken") ?? ""}`,
          },
          json: {
            hash: currentAccount?.address,
          },
        }).then(() => {
          setStashedWalletIsOpen(false);
          getBalance(currentAccount.address);
        });
      } catch (e) {
        console.log(e);
      }

      setStashedWalletIsOpen(false);
    }
    if (
      currentAccount?.address &&
      address &&
      isFetched &&
      !stashedWalletIsOpen
    ) {
      handleTransferClick();
    }
  }, [address, currentAccount, isFetched, stashedWalletIsOpen]);

  return (
    <>
      {isLoader && <Loader />}
      <ConnectModal
        open={stashedWalletIsOpen}
        trigger={<></>}
        onOpenChange={() => {
          console.log();
        }}
      />
      <Dialog
        modalWallpaper={walletBg}
        isOpen={isOpenTransfer}
        onClose={() => setIsOpenTransfer(false)}
        onAction={() => {
          setStashedWalletIsOpen(true);
          setIsOpenTransfer(false);
        }}
        title={"Old wallet successfully connected!"}
        text={
          <>
            <div
              onClick={() =>
                navigator.clipboard
                  .writeText(address ?? "")
                  .then(() =>
                    toastManagerStore.addToast("Address copied to clipboard")
                  )
              }
              className={styles.walletInfoContaienr}
            >
              <div className={styles.walletFinanceBlock}>
                <p>{shortenText(address ?? "undefined", 8)}</p>
                <WalletCopy />
              </div>
              <div className={styles.walletFinanceBlock}>
                <p>{balance(data?.totalBalance as string)} SUI</p>
              </div>
            </div>
            <p className={styles.walletText}>
              Let’s proceed by creating a new wallet
            </p>
          </>
        }
        modalTitleColor={"#C0C3FF"}
        modalIcon={iosWalletImg}
        buttonText={"Create a new wallet"}
      />
      <Dialog
        modalWallpaper={walletBg}
        isOpen={isTransferComplete}
        onClose={() => setIsOpenTransfer(false)}
        onAction={() => {
          sessionStorage.clear();
          handleClose();
        }}
        title={"Congratulations!"}
        text={
          <>
            <div
              onClick={() =>
                navigator.clipboard
                  .writeText(address ?? "")
                  .then(() =>
                    toastManagerStore.addToast("Address copied to clipboard")
                  )
              }
              className={styles.walletInfoContaienr}
            >
              <div className={styles.walletFinanceBlock}>
                <p>{shortenText(currentAccount?.address ?? "undefined", 8)}</p>
                <WalletCopy />
              </div>
              <div className={styles.walletFinanceBlock}>
                <p>{balance(stashedBalance)} SUI</p>
              </div>
            </div>
            <p className={styles.walletText}>
              Your SUI balance has been transferred to the new wallet! Use it
              in-game or withdraw it to any external wallet
            </p>
          </>
        }
        modalTitleColor={"#C0C3FF"}
        modalIcon={walletSuccess}
        buttonText={"Return to Telegram"}
      />
    </>
  );
};
