import GameStore from "./GameStore";

//import BoostTapStore from "./BoostTapStore";

import { observable, flow, action } from "mobx";
import { backend } from "@/services";
import { delay, isDevelopment, isProduction, isTelegram } from "@/utils";

//import BoostEnergyMaxStore from "./BoostEnergyMaxStore";
//import BoostEnergyRecoveryStore from "./BoostEnergyRecoveryStore";
//import BoostEnergyInstantStore from "./BoostEnergyInstantStore";
import i18next from "i18next";
import ToastManagerStore from "./ToastManager";
import StoryManagerStore from "./StoryManager";
import { stories } from "@/constants";
import WalletStore from "./WalletStore";

const flags = {
  avro: false,
  avroSchemas: false,
  objects: false,
  auth: false,
  ws: false,
  wsAuth: false,
  wsSync: false,
  initOptions: false,
  initState: false,
};

export type HapticType =
  | "off"
  | "light"
  | "medium"
  | "heavy"
  | "rigid"
  | "soft";

export default class RootStore {
  @observable public accessor loading: boolean = true;
  @observable public accessor error: boolean = false;
  public accessor initFlags: typeof flags = flags;
  public accessor initFlagsError: undefined | typeof flags = undefined;
  public accessor errorMessage = "";
  public accessor errorInInit = false;

  @observable.ref public accessor gameStore: GameStore;
  @observable.ref public accessor walletStore: WalletStore;
  @observable.ref public accessor toastManagerStore: ToastManagerStore;
  @observable.ref public accessor storyManagerStore: StoryManagerStore;

  @observable public accessor qr: boolean = false;
  @observable public accessor gameReady: boolean = false;
  @observable public accessor isEarly: boolean = false;

  @observable public accessor googleHash: string | undefined;

  @observable public accessor lang: string = i18next.language;
  @observable public accessor hapticType: HapticType = "soft";

  constructor() {
    this.gameStore = new GameStore(this);
    this.walletStore = new WalletStore(this);
    this.toastManagerStore = new ToastManagerStore(this);
    this.storyManagerStore = new StoryManagerStore(this);
  }

  @action.bound addErrorHandlers() {
    window.addEventListener("error", (event) => {
      this.error = true;
      if (!this.initFlagsError) {
        this.initFlagsError = Object.assign({}, this.initFlags);
      }
      if (!this.errorMessage) {
        this.errorMessage = event.error.message;
      }
    });
    window.addEventListener("unhandledrejection", (event) => {
      this.error = true;
      if (!this.initFlagsError) {
        this.initFlagsError = Object.assign({}, this.initFlags);
      }
      if (!this.errorMessage) {
        this.errorMessage = event.reason.message;
      }
    });
  }

  @action.bound initTelegram() {
    Telegram.WebApp.expand();
  }

  @action.bound setLang(lang: string) {
    this.lang = lang;
  }

  @action.bound setHapticType(type: HapticType) {
    this.hapticType = type;
  }

  @action.bound setLoading(value: boolean) {
    this.loading = value;
  }

  @action.bound setGoogleHash(value: string) {
    this.googleHash = value;
  }

  @flow.bound *init() {
    this.addErrorHandlers();
    i18next.on("languageChanged", this.setLang);
    stories.forEach((storySet) => this.storyManagerStore.addStorySet(storySet));
    console.groupCollapsed("init with", backend.constructor.name);
    try {
      stories.forEach((storySet) =>
        this.storyManagerStore.addStorySet(storySet)
      );

      if (isProduction) {
        const p = Telegram.WebApp.platform;
        if (["tdesktop", "macos", "weba", "web", "unknown"].includes(p)) {
          this.qr = true;
          return;
        }
      }

      const animationDelay = isDevelopment ? delay(0) : delay(4000);
      const loadTimeout = setTimeout(() => {
        console.groupEnd();
        this.errorInInit = true;
        throw new Error("Game load timeout");
      }, 20_000);

      if (isTelegram) this.initTelegram();

      yield delay(0); // macrotask for first frame render
      Telegram.WebApp.ready();

      yield backend.init();
      const options = backend.options;
      const state = backend.initState;
      if (options && state) {
        yield this.gameStore.init(options, state);
        this.isEarly = state.isEarly;
        const addres = localStorage.getItem("stashed:recentAddress");

        if (state.nft) {
          console.log("nft", state.nft);
          this.walletStore.setNftData(state.nft);
        }

        if (!addres) {
          if (state.wallet) {
            localStorage.setItem("stashed:recentAddress", state.wallet);
            localStorage.setItem(
              "sui-dapp-kit:wallet-connection-info",
              JSON.stringify({
                state: {
                  lastConnectedWalletName: "Stashed",
                  lastConnectedAccountAddress: state.wallet,
                },
                version: 0,
              })
            );
            this.walletStore.chageCreateStatus(true);
            this.walletStore.setStashedAddress(state.wallet);
          }
        } else {
          if (!state.wallet || state.wallet !== addres) {
            backend.sendWallet(addres as string);
            this.walletStore.chageCreateStatus(true);
            this.walletStore.setStashedAddress(addres);
          }
        }
      } else {
        throw "no options or state";
      }

      // if (!walletStore.isCreated) {
      //   backend.getContext("trust_wallet");
      // }

      this.gameReady = true;
      console.log("game ready");
      yield animationDelay;
      this.loading = false;

      clearTimeout(loadTimeout);
      console.groupEnd();
    } catch (e) {
      console.groupEnd();
      this.errorInInit = true;
      throw e;
    }
  }
}
