import { observer } from "mobx-react-lite";
import styles from "./styles.module.css";
import { leagueStore } from "@/store";

interface ProgressBarProps {
  img: string;
  index: number;
  leagueName: string;
}

export const LeagueFace = observer(
  ({ img, index, leagueName }: ProgressBarProps) => {
    const { level } = leagueStore;

    return (
      <div id={leagueName} className={styles.leagueFaceContainer}>
        <div
          className={`${styles.leagueFace} ${
            level === index ? styles.selectedLeague : ""
          }`}
        >
          <img src={img} alt={leagueName} />
        </div>
        <p className={styles.leagueName}>{leagueName}</p>
      </div>
    );
  }
);
