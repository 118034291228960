import { Transaction } from "@mysten/sui/transactions";
import { bcs } from "@mysten/bcs";
import { bech32 } from "bech32";
import { SIGNATURE_FLAG_TO_SCHEME } from "@mysten/sui/cryptography";

// region gamecard
export const buildMintNftTx = ({
  packageAddress,
  registryAddress,
  signature,
  league,
  points,
  sigValidUntil,
}: {
  packageAddress: string;
  registryAddress: string;
  sigValidUntil: number;
  league: number;
  points: number;
  signature: Uint8Array;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::mint`,
    arguments: [
      tx.object(registryAddress),
      tx.object("0x6"), // Clock object
      tx.pure.u64(sigValidUntil),
      tx.pure.option("u64", points),
      tx.pure.option("u64", league),
      tx.pure.vector("u8", signature),
    ],
  });
  tx.setGasBudget(50000000);
  return tx;
};

export const buildCheckinArgs = ({
  league,
  validUntil,
  points,
  user,
}: {
  league?: number;
  points?: number;
  validUntil: number;
  user: string;
}): Uint8Array => {
  const bcsS = bcs.struct("CheckinArgTBS", {
    validUntil: bcs.u64(),
    points: bcs.option(bcs.u64()),
    league: bcs.option(bcs.u64()),
    user: bcs.string(),
  });

  const checkinStruct = bcsS.serialize({
    validUntil,
    points,
    league,
    user,
  });

  return checkinStruct.toBytes();
};

export const buildCheckinTx = ({
  functionParams,
  packageAddress,
  userAddress,
}: {
  functionParams: {
    nftAddress: string;
    treasury: string;
    registry: string;
    fee: number;
    validUntil: number;
    points?: number;
    league?: number;
    signature: Uint8Array;
  };
  packageAddress: string;
  userAddress: string;
}) => {
  const tx = new Transaction();
  const [fee] = tx.splitCoins(tx.gas, [functionParams.fee]);
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::checkin`,
    arguments: [
      tx.object(functionParams.nftAddress),
      tx.object(functionParams.treasury),
      tx.object(functionParams.registry),
      tx.object("0x6"), // Clock object
      fee,
      tx.pure.u64(functionParams.validUntil),
      tx.pure.option("u64", functionParams.points),
      tx.pure.option("u64", functionParams.league),
      tx.pure.vector("u8", functionParams.signature),
    ],
  });
  tx.transferObjects([fee], userAddress);
  tx.setGasBudget(50000000);
  return tx;
};

export const buildGrandTreasuryTx = ({
  adminCap,
  registry,
  packageAddress,
}: {
  adminCap: string;
  registry: string;
  packageAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::grant_treasurer_cap`,
    arguments: [tx.object(adminCap), tx.object(registry)],
    typeArguments: [`${packageAddress}::capybara_portal::CAPYBARA_PORTAL`],
  });
  tx.setGasBudget(50000000);
  return tx;
};

export const buildSpendTransaction = ({
  amount,
  registry,
  nftId,
  packageAddress,
}: {
  registry: string;
  nftId: string;
  amount: number;
  packageAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::spend`,
    arguments: [tx.object(registry), tx.object(nftId), tx.pure.u64(amount)],
    typeArguments: [`${packageAddress}::capybara_portal::CAPYBARA_PORTAL`],
  });
  tx.setGasBudget(50000000);
  return tx;
};
// endregion
//region lootbox
export const buildMintLootBoxTx = ({
  dataStorage,
  uid,
  signature,
  packageAddress,
}: {
  dataStorage: string;
  uid: number;
  signature: Uint8Array;
  packageAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::mint_lootbox`,
    arguments: [
      tx.object(dataStorage),
      tx.pure.u64(uid),
      tx.pure.vector("u8", signature),
    ],
  });
  tx.setGasBudget(50000000);
  return tx;
};

export const buildOpenLootBoxTx = ({
  dataStorage,
  packageAddress,
  transferLootboxId,
}: {
  dataStorage: string;
  packageAddress: string;
  transferLootboxId: string;
}) => {
  const suiRandomAddress = "0x8";
  const tx = new Transaction();
  // const transferObRes = tx.transferObjects([tx.object(transferLootboxId)], packageAddress);
  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::open_lootbox`,
    arguments: [
      tx.object(dataStorage),
      tx.object(suiRandomAddress),
      tx.object(transferLootboxId),
    ],
  });

  tx.setGasBudget(50000000);
  return tx;
};

export const buildAdminMintFruitTx = ({
  fruitTypes,
  lootboxAdminCap,
  dataStorage,
  packageAddress,
}: {
  lootboxAdminCap: string;
  dataStorage: string;
  fruitTypes: Array<string>;
  packageAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::mint_fruit_by_admin`,
    arguments: [
      tx.object(lootboxAdminCap),
      tx.object(dataStorage),
      tx.pure.vector("string", fruitTypes),
    ],
  });
  tx.setGasBudget(50000000);
  return tx;
};

export const buildExchangeCapybaraTx = ({
  packageAddress,
  dataStorage,
  fruits,
}: {
  packageAddress: string;
  dataStorage: string;
  fruits: Array<string>;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::exchange_fruits_to_capybara`,
    arguments: [
      tx.object(dataStorage),
      ...fruits.map((fruit) => tx.object(fruit)),
    ],
  });

  tx.setGasBudget(50000000);
  return tx;
};
// endregion
export function decodeSuiPrivateKey(value: string) {
  const { words } = bech32.decode(value);

  const extendedSecretKey = new Uint8Array(bech32.fromWords(words));
  const secretKey = extendedSecretKey.slice(1);
  const signatureScheme =
    SIGNATURE_FLAG_TO_SCHEME[
      extendedSecretKey[0] as keyof typeof SIGNATURE_FLAG_TO_SCHEME
    ];
  return {
    schema: signatureScheme,
    secretKey: secretKey,
  };
}
