import ky from "ky";
import { isIOS } from "@/utils/handleExpandPage";
import { isProdUrl } from "@/constants";

export const handleClose = () => {
  if (isIOS) {
    window.open(
      `https://t.me/${isProdUrl ? "getcapybot" : "Kapybaradev_bot"}`,
      "_self"
    );
  } else {
    window.close();
  }
};

export const postTransaction = async (
  url: string,
  token: string,
  json: Record<string, string>
) => {
  try {
    await ky.post(url, {
      headers: { Authorization: `Bearer ${token}` },
      json,
    });
  } catch (error) {
    console.error("Transaction error:", error);
  }
};
