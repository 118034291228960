import styles from "./styles.module.css";
import { CoinsDisplay } from "@/components/CoinsDisplay";
import { tap, click } from "@/components/Capybara";
import { observer } from "mobx-react-lite";
import EnergyBar from "@/components/EnergyBar";
import { boostTapStore, gameStore, leagueStore, lootboxesStore, walletStore } from "@/store";
import Rocket from "@/components/Rocket";
import { capybaraLevels } from "@/constants";
import { CreateWallet } from "@/components/CreateWallet/CreateWallet";
import { Wallet } from "@/components/Wallet";

import { TutorialOverlay } from "./TutorialOverlay";
import { CapybaraNftCard } from "@/components/CapybaraNftCard";
import { isProduction } from "@/utils";
import { useNavigate } from "react-router-dom";
import { Bag } from "./Bag";
import { useCallback } from "react";

export const Feed = observer(() => {
  const { level } = leagueStore;
  const { balance, totalBalance } = gameStore;
  const { hasLootboxes } = lootboxesStore;
  const navigate = useNavigate();

  const handleBagClick = useCallback(() => {
    if (!hasLootboxes) return navigate("/buyMoneyBag");
    if (hasLootboxes) return navigate("/collections");
  }, [navigate, hasLootboxes]);

  return (
    <div className={styles.feedContainer}>
      {boostTapStore.isRocket && <Rocket />}
      <div className={styles.coinsBlockWrapper}>
        {walletStore.isCreated ? (
          <Wallet
            backgroundColor={capybaraLevels[level].walletBackgroundColor}
          />
        ) : (
          <CreateWallet
            backgroundColor={capybaraLevels[level].walletBackgroundColor}
          />
        )}
        <div className={styles.coins}>
          <CoinsDisplay />
          {!isProduction && (
            <>
              <Bag onClick={handleBagClick}/>
            </>
          )}
        </div>
        {/* <Team /> */}
      </div>
      <div className={styles.capybaraContainer}>
        {/* <Capybara level={level} /> */}

        <CapybaraNftCard />
        <EnergyBar />
      </div>
      <TutorialOverlay
        isNewbie={!balance && !totalBalance}
        onClick={click}
        onTap={tap}
      />
    </div>
  );
});
