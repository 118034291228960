import { useEffect, useState } from "react";
import styles from "./styles.module.sass";
import walletImg from "@/assets/capybara-images/walletImg.png";
import { Dialog } from "../Dialog";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import walletBg from "@/assets/capybara-images/walletBg.png";
import { observer } from "mobx-react-lite";
import { backend } from "@/services";
import { externalLink, isIOS } from "@/utils/handleExpandPage";
import rootStore from "@/store";
import { useLocation, useNavigate } from "react-router-dom";

interface CreateWalletButtonProps {
  backgroundColor: string;
}

let interval: NodeJS.Timeout;

export const CreateWallet = observer(
  ({ backgroundColor }: CreateWalletButtonProps) => {
    const location = useLocation();

    const navigate = useNavigate();
    const [isOpenTransfer, setIsOpenTransfer] = useState(false);

    const focus = () => {
      backend.getContext("trust_wallet");
    };

    useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      if (query.has("fromTask")) {
        navigate("/");
        setIsOpenTransfer(true);
      }

      if (isIOS) {
        window.addEventListener("focus", focus);
      }
      return () => {
        window.removeEventListener("focus", focus);
        clearInterval(interval);
      };
    }, [location]);

    const handleAuth = () => {
      setIsOpenTransfer(false);
      if (!isIOS) {
        interval = setInterval(() => {
          focus();
        }, 1000);
      }
      externalLink(rootStore.isEarly ? "/authWallets" : "/authStashed", {
        token: localStorage.getItem("token") ?? "",
      });
    };

    return (
      <div className={styles.walletWrapper}>
        <div
          onClick={() => setIsOpenTransfer(true)}
          style={{
            background: backgroundColor,
          }}
          className={styles.wallet}
        >
          <img src={walletImg} alt="walletImg" />
          <p>{"Create a Wallet"}</p>
        </div>

        <Dialog
          modalWallpaper={walletBg}
          isOpen={isOpenTransfer}
          onClose={() => setIsOpenTransfer(false)}
          onAction={() => handleAuth()}
          title={"Welcome to the Capybara v2.0!"}
          text={
            rootStore.isEarly
              ? "Let’s start by transferring your SUI balance from the Beta app to your new wallet"
              : "Let’s start by creating a new wallet"
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={
            rootStore.isEarly ? "Connect old wallet" : "Connect wallet"
          }
        />
      </div>
    );
  }
);
