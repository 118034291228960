import { FC } from "react";
import { observer } from "mobx-react-lite";

import capyBag from "@/assets/capybara-images/capybag.png";
import Info from "@/assets/icons/info.svg?react";
import Star1 from "@/assets/icons/star1.svg?react";
import Star2 from "@/assets/icons/star2.svg?react";
import { Button } from "@/components/Button";

import styles from './styles.module.sass';
import { Badge } from "@/components/Badge";
import { DialogComponents } from "@/components/Dialog";
import { TransactionFee } from "../TransactionFee";
import { MoneyBagTip } from "../MoneyBagTip";

type OpenMoneyBagProps = {
  isOpen: boolean;
  isFeeOpen: boolean;
  isBagTipOpen: boolean;
  hasSui: boolean;
  onAction: VoidFunction;
  onClose: VoidFunction;
  onBagTip: VoidFunction;
  onListForSale: VoidFunction;
  onFeeTip: VoidFunction;
  onFeeClose: VoidFunction;
  onBagTipClose: VoidFunction;
}

export const OpenMoneyBag: FC<OpenMoneyBagProps> = observer(({
  hasSui,
  isOpen,
  isFeeOpen,
  isBagTipOpen,
  onAction,
  onClose,
  onListForSale,
  onFeeTip,
  onBagTip,
  onFeeClose,
  onBagTipClose,
}) => {
  return (
    <>
      <DialogComponents.Container
        isOpen={isOpen}
        onClose={onClose}
        onAction={onAction}
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
      >
        {({ handleClose, handleAction }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              className={styles.modalCloser}
              onClick={handleClose}
            />
            <div className={styles.capybag}>
              <Star1 className={styles.star}/>
              <Star2 className={styles.star}/>
              <img alt="capybag" src={capyBag} style={{maxWidth: "323px"}}/>
              <Badge className={styles.tip} color="transparent" onClick={onBagTip}>
                <span>What's inside?</span>
                <Info width={12} height={12}/>
              </Badge>
            </div>
            <DialogComponents.TextContainer className={styles.textContainer}>
              <DialogComponents.Title className={styles.modalTitle}>
                {`You got a Money Bag!`}
              </DialogComponents.Title>
              <DialogComponents.Text className={styles.modalText}>
                {`You can open your Money Bag now\nor sell it on NFT marketplace`}
              </DialogComponents.Text>
            </DialogComponents.TextContainer>
            <div className={styles.controls}>
              <div className={styles.fee} onClick={onFeeTip}>
                <span className={styles.fee__name}>Transaction Fee</span>
                <span className={styles.fee__amount}>~0.002 SUI <Info width={18} height={18} style={{marginLeft: '10px'}}/></span>
              </div>
              {!hasSui && (
                <Button
                  onClick={() => null}
                  label="Not enough SUI"
                  styleType="info"
                  block={true}
                />
              )}
              {!!hasSui && (
                <Button
                  onClick={handleAction}
                  label="Open Money Bag"
                  styleType="primary"
                  block={true}
                />
              )}
              <Button
                onClick={onListForSale}
                label={"List for Sale"}
                styleType="outline"
                block={true}
              />
            </div>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
      <TransactionFee isOpen={isFeeOpen} onClose={onFeeClose} onAction={onFeeClose} />
      <MoneyBagTip isOpen={isBagTipOpen} onClose={onBagTipClose} onAction={onBagTipClose} />
    </>
  )
});