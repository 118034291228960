import styles from "./styles.module.css";
import blockedLeague from "@/assets/capybara-images/blockedLeague.png";
import { LeagueProgressBar } from "./LeagueProgressBar";
import { LeagueFace } from "./LeagueFace";
// import { LeagueUser } from "./LeagueUser";
import { leagueStore } from "@/store";
import starvingLeague from "@/assets/capybara-images/starvingLeague.png";
import { findLeagueConfig } from "@/utils/leagueConfig";

export const Leaderboard = () => {
  const { leagueName, leagues } = leagueStore;
  // const { balance } = gameStore;

  return (
    <div
      style={{
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        paddingBottom: "40px",
      }}
    >
      <div className={styles.title}>
        <p className={styles.coinsText}>{leagueName}</p>
        <LeagueProgressBar />
      </div>
      <div className={styles.leagues}>
        {leagues.map((item, index) => (
          <LeagueFace
            key={item.leagueNameEn}
            index={index}
            leagueName={item.leagueNameEn}
            img={
              findLeagueConfig(item.leagueNameEn)?.leagueFace ?? starvingLeague
            }
          />
        ))}
      </div>
      <div className={styles.dailyTasksWrapper}>
        <p className={styles.tasksTitle}>Leaderboard</p>
        <div
          className={`${styles.leaderboardWrapper} ${styles.leaderboardWrapperBlocked}`}
        >
          <div className={styles.blockedLeagueContainer}>
            <div className={styles.blockedLeagueImgContainer}>
              <img src={blockedLeague} alt="blocked league" />
            </div>
            <p className={styles.blockedLeagueText}>Leaderboard coming soon</p>
          </div>
          {/* {level !== 0 &&
            LeaderboardScore[1].map((item, index) => (
              <LeagueUser
                img={item.img}
                userName={item.name}
                index={index}
                coins={item.coins}
              />
            ))} */}
        </div>
      </div>
      {/* {level !== 0 && (
        <div className={styles.userRecordWrapper}>
          <div className={styles.userRecordContainer}>
            <LeagueUser
              img={taskIcon}
              userName={"you"}
              index={333333}
              coins={balance}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};
