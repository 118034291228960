import { MIST_PER_SUI } from "@mysten/sui/utils";

const numberFormatter = new Intl.NumberFormat("en-US");
const numberFormatterShort = new Intl.NumberFormat("en-US", {
  notation: "compact",
  maximumFractionDigits: 1,
});

export function formatNumber(num: number): string {
  return numberFormatter.format(Math.round(num));
}

export function formatNumberShort(num: number): string {
  return numberFormatterShort.format(Math.round(num));
}

const sec = 1000;
const min = sec * 60;
const hour = min * 60;
//const day = hour * 24;

export function formatEta(num: number): string {
  let t = num;
  //const days = Math.trunc(t / day);
  //t = t % day;
  const hours = String(Math.trunc(t / hour)).padStart(2, "0");
  t = t % hour;
  const mins = String(Math.trunc(t / min)).padStart(2, "0");
  t = t % min;
  const secs = String(Math.trunc(t / sec)).padStart(2, "0");
  t = t % sec;
  return `${hours}:${mins}:${secs}`;
}
export function shortenText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  }

  const charsToShow = maxLength / 2;
  const start = text.substring(0, charsToShow);
  const end = text.substring(text.length - charsToShow);

  return `${start}....${end}`;
}

export const balance = (totalBalance: string | number, length = 5) => {
  // if (Number(totalBalance) / Number(MIST_PER_SUI) < 0.02) {
  //   return "0";
  // }

  return (Number(totalBalance) / Number(MIST_PER_SUI) + "").substring(
    0,
    length
  );
};

export const calculateTimeLeft = (cooldown: number) => {
  const hoursLeft = cooldown / 3600000;
  if (hoursLeft < 1) {
    return `${Math.floor(hoursLeft * 60)} minutes`;
  } else {
    return `${Math.floor(hoursLeft)} hours`;
  }
};
